.Actions_container__xeyp6 {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.Actions_left__KDzAT {
  -webkit-justify-content: start;
          justify-content: start;
}

.Actions_center__bmw6_ {
  -webkit-justify-content: center;
          justify-content: center;
}

.Actions_right__bat4C {
  -webkit-justify-content: end;
          justify-content: end;
}
.DOMButtonOrA_default__14ULL {
  cursor: pointer;
}

@-webkit-keyframes Spinner_spinnerCircular__3ycTb {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_spinnerCircular__3ycTb {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Spinner_circular__1Nd73 {
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.Spinner_circular__1Nd73 span {
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border-color: var(--color-dark) transparent transparent transparent;
  -webkit-animation: Spinner_spinnerCircular__3ycTb 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Spinner_spinnerCircular__3ycTb 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.Spinner_inverse__s1QOf span {
  border-top-color: var(--color-white);
}

.Spinner_circular__1Nd73 span:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.Spinner_circular__1Nd73 span:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.Spinner_circular__1Nd73 span:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

.Spinner_size-normal__2FYc8 {
  width: 32px;
  height: 32px;
}

.Spinner_size-tiniest__1ejr6 {
  width: 10px;
  height: 10px;
}

.Spinner_size-tiny__2DLrL {
  width: 16px;
  height: 16px;
}

.Spinner_size-tiniest__1ejr6 span,
.Spinner_size-tiny__2DLrL span {
  border-width: 1px;
}

.Spinner_size-small__1g2gV {
  width: 22px;
  height: 22px;
}

.Spinner_size-medium__lqk7I {
  width: 42px;
  height: 42px;
}

.Spinner_size-large__25FtG {
  width: 64px;
  height: 64px;
}

.Fixed_wrapper__Xr88I {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Fixed_content__1n3HA {
  padding: 1rem;
  border-radius: 10em;
  background-color: var(--color-white);
  display: block;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.12);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: tralsnate(-50%, -50%);
          transform: tralsnate(-50%, -50%);
}

.Text_muted__3xLSX {
  color: var(--color-muted);
}

.Text_left__6j30Q {
  text-align: left;
}

.Text_right__1Nytx {
  text-align: right;
}

.Text_center__2qNqZ {
  text-align: center;
}

.Text_small__3LtoR {
  font-size: var(--text-size-small);
}
.Interface_wrapper__2b0gu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 20em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Interface_content__1tfsL {
  padding: 2rem;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.Column_size-1__3EaLX, .Column_size-2__slPFj, .Column_size-3__3XHC3, .Column_size-4__3EkAW, .Column_size-5__2HynN, .Column_size-6__3IAgf, .Column_size-7__38fac, .Column_size-8__1D0dw, .Column_size-9__11I4n, .Column_size-10__JxRHc, .Column_size-11__yUqaD, .Column_size-12__TtFDz, .Column_default__vKb_U,
.Column_size-auto__1xuSj, .Column_size-sm-1__3x0-8, .Column_size-sm-2__3LOZE, .Column_size-sm-3__3OHqu, .Column_size-sm-4__1QPYI, .Column_size-sm-5__a4TWZ, .Column_size-sm-6__2ZNhG, .Column_size-sm-7__1CKfk, .Column_size-sm-8__p9Ufu, .Column_size-sm-9____uYZ, .Column_size-sm-10__1Ze0n, .Column_size-sm-11__2E1Ou, .Column_size-sm-12__1N5jb, .Column_size-sm__iZJ7d,
.Column_size-sm-auto__I8Jy4, .Column_size-md-1__1seGX, .Column_size-md-2__2CqV2, .Column_size-md-3__iI4_f, .Column_size-md-4__kUoHS, .Column_size-md-5__13p1n, .Column_size-md-6__3icpC, .Column_size-md-7__9ZWHm, .Column_size-md-8__1eHQS, .Column_size-md-9__22itw, .Column_size-md-10__2MLwZ, .Column_size-md-11__ADglk, .Column_size-md-12__YBX9i, .Column_size-md__3BjSE,
.Column_size-md-auto__1EtRL, .Column_size-lg-1__3Ugk4, .Column_size-lg-2__3_9PT, .Column_size-lg-3__11DDe, .Column_size-lg-4__MKvjm, .Column_size-lg-5__3scck, .Column_size-lg-6__2yRmG, .Column_size-lg-7__38x1y, .Column_size-lg-8__320db, .Column_size-lg-9__1ep0p, .Column_size-lg-10__367JO, .Column_size-lg-11__1lIWp, .Column_size-lg-12__TClkw, .Column_size-lg__3N-S7,
.Column_size-lg-auto____7nI, .Column_size-xl-1__210zq, .Column_size-xl-2__gE-0r, .Column_size-xl-3__1BWZR, .Column_size-xl-4__1ounc, .Column_size-xl-5__2XO1T, .Column_size-xl-6__12-kL, .Column_size-xl-7__3v-ob, .Column_size-xl-8__3k49Z, .Column_size-xl-9__1rtjc, .Column_size-xl-10__1Eltv, .Column_size-xl-11__3x2Dh, .Column_size-xl-12__nJlk8, .Column_size-xl__2AWe8,
.Column_size-xl-auto__3vb6L {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.Column_default__vKb_U {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}

.Column_auto__3KACH {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.Column_noGutter__3dLlI {
  padding-left: 0;
  padding-right: 0;
}

.Column_size-1__3EaLX {
  -webkit-flex: 0 0 8.333333%;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.Column_size-2__slPFj {
  -webkit-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.Column_size-3__3XHC3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.Column_size-4__3EkAW {
  -webkit-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.Column_size-5__2HynN {
  -webkit-flex: 0 0 41.666667%;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.Column_size-6__3IAgf {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.Column_size-7__38fac {
  -webkit-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.Column_size-8__1D0dw {
  -webkit-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.Column_size-9__11I4n {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.Column_size-10__JxRHc {
  -webkit-flex: 0 0 83.333333%;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.Column_size-11__yUqaD {
  -webkit-flex: 0 0 91.666667%;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.Column_size-12__TtFDz {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.Column_order-first__3bdYk {
  -webkit-order: -1;
          order: -1;
}

.Column_order-last__2dAqo {
  -webkit-order: 13;
          order: 13;
}

.Column_order-0__2I8gc {
  -webkit-order: 0;
          order: 0;
}

.Column_order-1__kYkaZ {
  -webkit-order: 1;
          order: 1;
}

.Column_order-2__3BCAG {
  -webkit-order: 2;
          order: 2;
}

.Column_order-3__2ODpy {
  -webkit-order: 3;
          order: 3;
}

.Column_order-4__2qNt3 {
  -webkit-order: 4;
          order: 4;
}

.Column_order-5__1JA6a {
  -webkit-order: 5;
          order: 5;
}

.Column_order-6__27ns3 {
  -webkit-order: 6;
          order: 6;
}

.Column_order-7__2hgnR {
  -webkit-order: 7;
          order: 7;
}

.Column_order-8__3lcMr {
  -webkit-order: 8;
          order: 8;
}

.Column_order-9__3eUyI {
  -webkit-order: 9;
          order: 9;
}

.Column_order-10__3D0m7 {
  -webkit-order: 10;
          order: 10;
}

.Column_order-11__3wIxq {
  -webkit-order: 11;
          order: 11;
}

.Column_order-12__Z4uza {
  -webkit-order: 12;
          order: 12;
}

.Column_offset-1__1nLTX {
  margin-left: 8.333333%;
}

.Column_offset-2__2V9fB {
  margin-left: 16.666667%;
}

.Column_offset-3__1906a {
  margin-left: 25%;
}

.Column_offset-4__g_WSr {
  margin-left: 33.333333%;
}

.Column_offset-5__2vBa0 {
  margin-left: 41.666667%;
}

.Column_offset-6__20Dxt {
  margin-left: 50%;
}

.Column_offset-7__2OR3C {
  margin-left: 58.333333%;
}

.Column_offset-8__1u0gn {
  margin-left: 66.666667%;
}

.Column_offset-9__2n_el {
  margin-left: 75%;
}

.Column_offset-10__iteP_ {
  margin-left: 83.333333%;
}

.Column_offset-11__AOggl {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .Column_size-sm__iZJ7d {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .Column_size-sm-auto__I8Jy4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .Column_size-sm-1__3x0-8 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .Column_size-sm-2__3LOZE {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .Column_size-sm-3__3OHqu {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .Column_size-sm-4__1QPYI {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .Column_size-sm-5__a4TWZ {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .Column_size-sm-6__2ZNhG {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .Column_size-sm-7__1CKfk {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .Column_size-sm-8__p9Ufu {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .Column_size-sm-9____uYZ {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .Column_size-sm-10__1Ze0n {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .Column_size-sm-11__2E1Ou {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .Column_size-sm-12__1N5jb {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .Column_order-sm-first__g6TKx {
    -webkit-order: -1;
            order: -1;
  }

  .Column_order-sm-last__1yft1 {
    -webkit-order: 13;
            order: 13;
  }

  .Column_order-sm-0__2QF6W {
    -webkit-order: 0;
            order: 0;
  }

  .Column_order-sm-1__3LtWc {
    -webkit-order: 1;
            order: 1;
  }

  .Column_order-sm-2__uXrq_ {
    -webkit-order: 2;
            order: 2;
  }

  .Column_order-sm-3__31zaQ {
    -webkit-order: 3;
            order: 3;
  }

  .Column_order-sm-4__tAiqE {
    -webkit-order: 4;
            order: 4;
  }

  .Column_order-sm-5__7j22Y {
    -webkit-order: 5;
            order: 5;
  }

  .Column_order-sm-6__34vzO {
    -webkit-order: 6;
            order: 6;
  }

  .Column_order-sm-7__TaVmw {
    -webkit-order: 7;
            order: 7;
  }

  .Column_order-sm-8__12l2q {
    -webkit-order: 8;
            order: 8;
  }

  .Column_order-sm-9__2eAFB {
    -webkit-order: 9;
            order: 9;
  }

  .Column_order-sm-10__2lMYo {
    -webkit-order: 10;
            order: 10;
  }

  .Column_order-sm-11__3CvGm {
    -webkit-order: 11;
            order: 11;
  }

  .Column_order-sm-12__9BRbP {
    -webkit-order: 12;
            order: 12;
  }

  .Column_offset-sm-0__3qdil {
    margin-left: 0;
  }

  .Column_offset-sm-1__1opih {
    margin-left: 8.333333%;
  }

  .Column_offset-sm-2__1QVwo {
    margin-left: 16.666667%;
  }

  .Column_offset-sm-3__3tieF {
    margin-left: 25%;
  }

  .Column_offset-sm-4__2Xqh- {
    margin-left: 33.333333%;
  }

  .Column_offset-sm-5__20GO4 {
    margin-left: 41.666667%;
  }

  .Column_offset-sm-6__aMcgS {
    margin-left: 50%;
  }

  .Column_offset-sm-7__1znSq {
    margin-left: 58.333333%;
  }

  .Column_offset-sm-8__2PxQq {
    margin-left: 66.666667%;
  }

  .Column_offset-sm-9__PeZuE {
    margin-left: 75%;
  }

  .Column_offset-sm-10__jn6Za {
    margin-left: 83.333333%;
  }

  .Column_offset-sm-11__1wSvx {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .Column_size-md__3BjSE {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .Column_size-md-auto__1EtRL {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .Column_size-md-1__1seGX {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .Column_size-md-2__2CqV2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .Column_size-md-3__iI4_f {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .Column_size-md-4__kUoHS {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .Column_size-md-5__13p1n {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .Column_size-md-6__3icpC {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  
  .Column_size-md-7__9ZWHm {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .Column_size-md-8__1eHQS {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .Column_size-md-9__22itw {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .Column_size-md-10__2MLwZ {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .Column_size-md-11__ADglk {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .Column_size-md-12__YBX9i {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .Column_order-md-first__2dcQO {
    -webkit-order: -1;
            order: -1;
  }

  .Column_order-md-last__2_Ma8 {
    -webkit-order: 13;
            order: 13;
  }

  .Column_order-md-0__HtyIw {
    -webkit-order: 0;
            order: 0;
  }

  .Column_order-md-1__BG4Ce {
    -webkit-order: 1;
            order: 1;
  }

  .Column_order-md-2__1e2O- {
    -webkit-order: 2;
            order: 2;
  }

  .Column_order-md-3__1CkF8 {
    -webkit-order: 3;
            order: 3;
  }

  .Column_order-md-4__31wYB {
    -webkit-order: 4;
            order: 4;
  }

  .Column_order-md-5__R4QqI {
    -webkit-order: 5;
            order: 5;
  }

  .Column_order-md-6__3B7WJ {
    -webkit-order: 6;
            order: 6;
  }

  .Column_order-md-7__7g1iO {
    -webkit-order: 7;
            order: 7;
  }

  .Column_order-md-8__2G6n7 {
    -webkit-order: 8;
            order: 8;
  }

  .Column_order-md-9__5Tias {
    -webkit-order: 9;
            order: 9;
  }

  .Column_order-md-10__1U2vC {
    -webkit-order: 10;
            order: 10;
  }

  .Column_order-md-11__2pKYw {
    -webkit-order: 11;
            order: 11;
  }

  .Column_order-md-12__2mjL3 {
    -webkit-order: 12;
            order: 12;
  }
  
  .Column_offset-md-0__2O6Mr {
    margin-left: 0;
  }

  .Column_offset-md-1__1-RNL {
    margin-left: 8.333333%;
  }

  .Column_offset-md-2__3bmL2 {
    margin-left: 16.666667%;
  }

  .Column_offset-md-3__30MMN {
    margin-left: 25%;
  }

  .Column_offset-md-4__S6eM8 {
    margin-left: 33.333333%;
  }

  .Column_offset-md-5__1rtJL {
    margin-left: 41.666667%;
  }

  .Column_offset-md-6__3YCZH {
    margin-left: 50%;
  }

  .Column_offset-md-7__2BBi- {
    margin-left: 58.333333%;
  }

  .Column_offset-md-8__2WR72 {
    margin-left: 66.666667%;
  }

  .Column_offset-md-9__3wUjm {
    margin-left: 75%;
  }

  .Column_offset-md-10__1hUWR {
    margin-left: 83.333333%;
  }

  .Column_offset-md-11__3k7zo {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .Column_size-lg__3N-S7 {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .Column_size-lg-auto____7nI {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .Column_size-lg-1__3Ugk4 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .Column_size-lg-2__3_9PT {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .Column_size-lg-3__11DDe {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .Column_size-lg-4__MKvjm {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .Column_size-lg-5__3scck {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .Column_size-lg-6__2yRmG {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .Column_size-lg-7__38x1y {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .Column_size-lg-8__320db {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .Column_size-lg-9__1ep0p {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .Column_size-lg-10__367JO {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .Column_size-lg-11__1lIWp {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .Column_size-lg-12__TClkw {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .Column_order-lg-first__ZDA_8 {
    -webkit-order: -1;
            order: -1;
  }

  .Column_order-lg-last__2G2Gj {
    -webkit-order: 13;
            order: 13;
  }

  .Column_order-lg-0__21Twd {
    -webkit-order: 0;
            order: 0;
  }

  .Column_order-lg-1__1OxCA {
    -webkit-order: 1;
            order: 1;
  }

  .Column_order-lg-2__3LkXb {
    -webkit-order: 2;
            order: 2;
  }

  .Column_order-lg-3__nNYI5 {
    -webkit-order: 3;
            order: 3;
  }

  .Column_order-lg-4__24spX {
    -webkit-order: 4;
            order: 4;
  }

  .Column_order-lg-5__3ecmm {
    -webkit-order: 5;
            order: 5;
  }

  .Column_order-lg-6__1ZgZd {
    -webkit-order: 6;
            order: 6;
  }

  .Column_order-lg-7__31dOi {
    -webkit-order: 7;
            order: 7;
  }

  .Column_order-lg-8__wos-Z {
    -webkit-order: 8;
            order: 8;
  }

  .Column_order-lg-9__1dZJX {
    -webkit-order: 9;
            order: 9;
  }

  .Column_order-lg-10__27CTm {
    -webkit-order: 10;
            order: 10;
  }

  .Column_order-lg-11__37RFG {
    -webkit-order: 11;
            order: 11;
  }

  .Column_order-lg-12__5LSJ9 {
    -webkit-order: 12;
            order: 12;
  }
  
  .Column_offset-lg-0__W-xuQ {
    margin-left: 0;
  }

  .Column_offset-lg-1__c66nX {
    margin-left: 8.333333%;
  }

  .Column_offset-lg-2__1t7bv {
    margin-left: 16.666667%;
  }

  .Column_offset-lg-3__1VzRU {
    margin-left: 25%;
  }
  
  .Column_offset-lg-4__-YTp1 {
    margin-left: 33.333333%;
  }

  .Column_offset-lg-5__1Vx0f {
    margin-left: 41.666667%;
  }

  .Column_offset-lg-6__D0Tr3 {
    margin-left: 50%;
  }

  .Column_offset-lg-7__1lC4r {
    margin-left: 58.333333%;
  }

  .Column_offset-lg-8__1wb11 {
    margin-left: 66.666667%;
  }

  .Column_offset-lg-9__2mcFq {
    margin-left: 75%;
  }

  .Column_offset-lg-10__24E4e {
    margin-left: 83.333333%;
  }

  .Column_offset-lg-11__CDRGk {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .Column_size-xl__2AWe8 {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .Column_size-xl-auto__3vb6L {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .Column_size-xl-1__210zq {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .Column_size-xl-2__gE-0r {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .Column_size-xl-3__1BWZR {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .Column_size-xl-4__1ounc {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .Column_size-xl-5__2XO1T {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .Column_size-xl-6__12-kL {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .Column_size-xl-7__3v-ob {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .Column_size-xl-8__3k49Z {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .Column_size-xl-9__1rtjc {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .Column_size-xl-10__1Eltv {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .Column_size-xl-11__3x2Dh {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .Column_size-xl-12__nJlk8 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .Column_order-xl-first__3eVEG {
    -webkit-order: -1;
            order: -1;
  }

  .Column_order-xl-last__3wNpW {
    -webkit-order: 13;
            order: 13;
  }

  .Column_order-xl-0__1dx1y {
    -webkit-order: 0;
            order: 0;
  }

  .Column_order-xl-1__rdbf0 {
    -webkit-order: 1;
            order: 1;
  }

  .Column_order-xl-2__2EaiH {
    -webkit-order: 2;
            order: 2;
  }
  
  .Column_order-xl-3__21AlC {
    -webkit-order: 3;
            order: 3;
  }

  .Column_order-xl-4__3xVSR {
    -webkit-order: 4;
            order: 4;
  }

  .Column_order-xl-5__1R0Y4 {
    -webkit-order: 5;
            order: 5;
  }

  .Column_order-xl-6__dwbB3 {
    -webkit-order: 6;
            order: 6;
  }

  .Column_order-xl-7__QWx1t {
    -webkit-order: 7;
            order: 7;
  }

  .Column_order-xl-8__12ecA {
    -webkit-order: 8;
            order: 8;
  }

  .Column_order-xl-9__2B5Nj {
    -webkit-order: 9;
            order: 9;
  }

  .Column_order-xl-10__IjmmR {
    -webkit-order: 10;
            order: 10;
  }
  
  .Column_order-xl-11__Ak1PI {
    -webkit-order: 11;
            order: 11;
  }

  .Column_order-xl-12__kHSoU {
    -webkit-order: 12;
            order: 12;
  }

  .Column_offset-xl-0__2vhnI {
    margin-left: 0;
  }

  .Column_offset-xl-1__3peES {
    margin-left: 8.333333%;
  }

  .Column_offset-xl-2__14ikZ {
    margin-left: 16.666667%;
  }

  .Column_offset-xl-3__323E2 {
    margin-left: 25%;
  }

  .Column_offset-xl-4__1ahXf {
    margin-left: 33.333333%;
  }

  .Column_offset-xl-5__y8bzW {
    margin-left: 41.666667%;
  }

  .Column_offset-xl-6__1f2xH {
    margin-left: 50%;
  }

  .Column_offset-xl-7__2BeY3 {
    margin-left: 58.333333%;
  }

  .Column_offset-xl-8__1XFnX {
    margin-left: 66.666667%;
  }

  .Column_offset-xl-9__2ketq {
    margin-left: 75%;
  }

  .Column_offset-xl-10__xE-R9 {
    margin-left: 83.333333%;
  }

  .Column_offset-xl-11__1iNem {
    margin-left: 91.666667%;
  }
}
.Container_default__22qRI {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .Container_default__22qRI {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .Container_default__22qRI {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .Container_default__22qRI {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .Container_default__22qRI {
    max-width: 1140px;
  }
}
.Row_default__1SdLx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.Row_justify-content-around__3_zet {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.Row_align-items-start__1a0_I {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.Row_align-items-end__2pyFz {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.Row_align-items-center__zPeD- {
  -webkit-align-items: center;
          align-items: center;
}

.Row_align-items-baseline__37Zgu {
  -webkit-align-items: baseline;
          align-items: baseline;
}

.Row_align-items-stretch__V0u2s {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.Row_no-gutter__e2U67 {
  margin-left: 0;
  margin-right: 0;
}
.Rule_default__1Yt5y {
  border-style: solid;
  border-top-color: var(--color-rule);
  border-width: 1px 0 0 0;
  margin-top: 24px;
  margin-bottom: 24px;
}
.Icon_wrapper__3hq8t {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.Icon_block__be_88, .Icon_block__be_88 svg {
  display: block;
}

.Icon_small__1mziI svg {
  width: 16px;
  height: auto;
}

.Icon_medium__1sNww svg {
  width: 32px;
  height: auto;
}

.Icon_large__1jhBm svg {
  width: 64px;
  height: auto;
}

.Icon_extraLarge__1Xt_k svg {
  width: 120px;
  height: auto;
}

.Channels_link__3IXQp {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 0.315rem;
  color: var(--color-muted);
}

.Channels_link__3IXQp::after {
  content: "|";
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 0.5rem 0 1rem;
  color: rgba(0,0,0,0.12);
}

.Channels_link__3IXQp:hover {
  color: var(--color-dark );
}

.Channels_link__3IXQp svg {
  color: currentColor;
  max-width: 16px;
  height: auto;
}
.Footer_default__34b5d {
  position: relative;
  width: 100%;
  margin-top: auto;
  padding-bottom: 1rem;
  z-index: 1001;
}

.Footer_noMargin__1n6_E {
  margin-top: 0;
}
.Header_default__UvAG1 {
  position: relative;
  width: 100%;
  z-index: 1001;
}
.Main_layout__3RodA {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 1050;
  overflow-y: auto;
  position: relative;
  z-index: 10;
}

@media (min-width: 1200px) {
  .Main_layout__3RodA {
    height: 100%;
  }
}

.FlexBar_flex__1nYzp {
  display: -webkit-flex;
  display: flex;
}

.FlexBar_inline__1rNK7 {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.FlexBar_verticalAlign__EsaDt {
  -webkit-align-items: center;
          align-items: center;
}

.FlexBar_justify-content-start__2NCQ6 {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.FlexBar_justify-content-end__3rlPG {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.FlexBar_justify-content-center__2Ij2c {
  -webkit-justify-content: center;
          justify-content: center;
}

.FlexBar_justify-content-between__2zQkh {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.FlexBar_justify-content-around__2ytwY {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.FlexBar_align-items-start__2tXTQ {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.FlexBar_align-items-end__3AdPk {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.FlexBar_align-items-center__3qmOn {
  -webkit-align-items: center;
          align-items: center;
}

.FlexBar_align-items-baseline__gIM1q {
  -webkit-align-items: baseline;
          align-items: baseline;
}

.FlexBar_align-items-stretch__1g1Ju {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.FlexBar_align-content-start__1ylXz {
  -webkit-align-content: flex-start;
          align-content: flex-start;
}

.FlexBar_align-content-end__1HFkl {
  -webkit-align-content: flex-end;
          align-content: flex-end;
}

.FlexBar_align-content-center__3L7sY {
  -webkit-align-content: center;
          align-content: center;
}

.FlexBar_align-content-between__2_aMY {
  -webkit-align-content: space-between;
          align-content: space-between;
}

.FlexBar_align-content-around__3TBBU {
  -webkit-align-content: space-around;
          align-content: space-around;
}

.FlexBar_align-content-stretch__2g0MS {
  -webkit-align-content: stretch;
          align-content: stretch;
}
.Brand_default__34jdH {
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: var(--text-size-medium);
  font-weight: var(--text-weight-bolder);
  line-height: inherit;
  white-space: nowrap;
}

a.Brand_default__34jdH {
  color: var(--color-dark);
  text-decoration: none;
}
.Alert_default__tugfM {
  position: relative;
  padding: 0.615rem 1.25rem;
  margin-bottom: 1rem;
  border: 3px solid transparent;
  border-width: 0 0 0 3px;
}

.Alert_error__2BlfD {
  color: var(--alert-danger-text);
  background-color: var(--alert-danger-color);
  border-color: var(--alert-danger-border);
}

.Alert_info__sOeFi {
  color: var(--alert-info-text);
  background-color: var(--alert-info-color);
  border-color: var(--alert-info-border);
}

.Title_level-1__PvscQ {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-1);
}

.Title_level-2__TvAK0 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-2);
}

.Title_level-3__80_rk {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-3);
}

.Title_level-4__3oh6Q {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-4);
}

.Title_level-5__3jtHr {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-5);
}

.Title_level-6__3psev {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-6);
}
.Avatar_default__1-kuE {
  display: block;
  max-width: 100%;
  height: auto !important;
}

.Avatar_loading__25ydt {
  display: block;
  background-color: var(--color-loading);
  max-width: 100%;
  height: auto !important;
}

.Cell_td__14l8B, .Cell_th__10ZNI {
  text-align: left;
  vertical-align: middle;
}

.Cell_th__10ZNI {
  padding: 0.75rem 0;
  font-weight: var(--text-weight-bold);
}

.Cell_td__14l8B {
  padding: 0.5rem 0;
  border-top: 1px solid var(--row-line-color);
  line-height: 1.5;
  font-size: var(--text-size-normal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Row_hightlight__1bu2K:hover td {
  background-color: var(--color-light);
}
@media (min-width: 1200px) {
  .Summary_content__2x-yY {
    margin-top: auto;
  }
}
.Table_wrapper__1kMh3 {
  width: 100%;
}

.Table_default__QmqQw {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 1200px) {
  .Table_wrapper__1kMh3 {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.Profile_title__2A4Js {
  font-size: var(--title-display-secondary-size);
  font-weight: var(--text-weight-bolder);
}

.Profile_text__2f5Hi {
  color: var(--color-muted);
  font-size: var(--text-size-lead);
  font-weight: var(--text-weight-normal);
}

.Profile_row__3qa3L {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Profile_logoWrapper__v5iXo {
  -webkit-flex: 0 0 40%;
          flex: 0 0 40%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 40%;
}

.Profile_logoWrapper__v5iXo svg {
  max-width: 100%;
  height: auto;
}

.Profile_content__2mYix {
  padding-top: 2rem;
  padding-bottom: 2rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex: 0 0 60%;
          flex: 0 0 60%;
  max-width: 60%;
}

@media (min-width: 1200px) {
  .Profile_container__2rqP8 {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
  }
}
.Dropdown_default__4mUoy {
  position: relative;
}

.Dropdown_show__27jLb {
  display: block;
}

.Dropdown_toggle__371D9 {
  -webkit-align-items: center;
          align-items: center;
  color: var(--color-text);
  display: -webkit-inline-flex;
  display: inline-flex;
  white-space: nowrap;
  text-align: inherit;
  background-color: transparent;
  border-width: 0;
}

.Dropdown_toggle__371D9::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.Dropdown_toggle__371D9:empty::after {
  margin-left: 0;
}

.Dropdown_menu__X4JDH {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: var(--text-size-normal);
  color: var(--color-text);
  text-align: left;
  list-style: none;
  background-color: var(--color-white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15rem;
}

.Dropdown_menu-show__EAx1L {
  display: block;
}

.Dropdown_menu-left__3iawV {
  left: 0;
  right: auto;
}

.Dropdown_menu-right__3ciX7 {
  left: auto;
  right: 0;
}

.Dropdown_item__13s9Y {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--color-text);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.Profile_avatar__3UGHT {
  border-radius: 10em;
}

.Profile_toggle__3i05E {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.Navbar_default__1ReEi {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-rule);
}
.Layout_common__iUOSm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;  
  width: 100%;
  border-top: 4px solid var(--color-yellow);
  min-height: calc(100vh - 4px);
}

.Layout_flex__KEU3d {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Layout_horizontallyCentered__2xNG4 {
  -webkit-justify-content: center;
          justify-content: center;
}

.Layout_verticalCentered__3SUjd {
  -webkit-align-items: center;
          align-items: center;
}

@media (min-width: 1200px) {
  .Layout_common__iUOSm {
    height: 100%;
    min-height: 450px;
  }

  .Layout_auto__2YqjW {
    min-height: auto;
  }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  /* colors */
  --color-white: #ffffff;
  --color-text: #24292e;
  --color-rule: #e1e1e1;
  --color-muted: #888888;
  --color-dark: #000000;
  --color-yellow: #f5eb09;
  --color-blue: #0366d6;
  --color-light: #fafafa;
  --color-loading: #f4f4f4;

  /* controls */
  --control-text: #495057;
  --control-color: var(--color-white);
  --control-border: #ced4da;
  --control-padding: 0.375rem 0.6rem;
  --control-font-size: 14px;
  --control-placeholder: rgba(0,0,0,0.3);
  --control-disabled: #e9ecef;
  --control-color-focus: var(--color-white);
  --control-border-focus: var(--color-dark);
  --control-text-focus: #495057;
  --control-box-shadow-focus: rgba(0,0,0,0.1);
  --control-custom-color: #ffffff;
  --control-custom-border: #adb5bd;
  --control-custom-active-text: #ffffff;
  --control-custom-active-color: #000000;

  /* badges */
  --badge-primary-color: transparent;
  --badge-primary-text: #111111;
  --badge-primary-border: #dddddd;

  --badge-success-color: transparent;
  --badge-success-text: green;
  --badge-success-border: green;

  --badge-danger-color: transparent;
  --badge-danger-text: red;
  --badge-danger-border: red;
  
  /* buttons */
  --button-color-default: #f4f4f4;
  --button-text-default: #212529;
  --button-hover-default: #f1f1f1;
  --button-hoverText-default: #212529;

  --button-color-primary: #f5eb09;
  --button-text-primary: #000000;
  --button-hover-primary: #000000;
  --button-hoverText-primary: #ffffff;

  --button-color-secondary: #000000;
  --button-text-secondary: #ffffff;
  --button-hover-secondary: #111111;
  --button-hoverText-secondary: #ffffff;

  --button-text-danger: #f23459;
  --button-color-danger: transparent;
  --button-border-danger: transparent;

  --button-hover-danger: #f23459;
  --button-hoverText-danger: #ffffff;
  --button-hoverBorder-danger: #f23459;

  /* background colors */
  --background-gray-dark: #24292e;

  /* normal text */
  --text-size-small: 11px;
  --text-size-normal: 14px;
  --text-size-medium: 16px;
  --text-size-lead: 18px;
  --text-weight-normal: 400;
  --text-weight-medium: 600;
  --text-weight-bold: 700;
  --text-weight-bolder: 900;

  --title-primary: 41px;
  --title-secondary: 38px;
  --title-tertiary: 32px;

  --title-size-1: 30px;
  --title-size-2: 25px;
  --title-size-3: 16px;
  --title-size-4: 14px;
  --title-size-5: 12px;
  --title-size-6: 10px;

  /* titles */
  --title-display-primary-size: 54px;
  --title-display-secondary-size: 48px;

  /* tables */
  --row-line-color: #eaecef;

  /* alert */
  --alert-danger-color: #FFF9FB;
  --alert-danger-border: #FFCCD7;
  --alert-danger-text: #F23459;

  --alert-info-color: #FFF9EF;
  --alert-info-border: #FDD88E;
  --alert-info-text: #B24319;

  /* font families */
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-serif: serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  min-height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: var(--color-white);
  color: var(--color-text);
  font-family: var(--font-family-sans-serif);
  font-size: var(--text-size-normal);
  line-height: 1.5;
  min-height: 100vh;
  word-wrap: break-word;
}

.full-height {
  min-height: 100vh;
}

@media (min-width: 1200px) {
  .full-height {
    height: 100vh;
  }
}

/* svg logo */
.st0 { fill:var(--color-dark); }
.st1 { fill: none; stroke: var(--color-yellow); stroke-width: 0.5; stroke-miterlimit: 10;}
.st2 { fill: var(--color-yellow); }
.st3 { fill: var(--color-yellow); }
.st4 { fill: none; stroke: var(--color-yellow); stroke-miterlimit: 10; }
.st5 { fill: var(--color-white); }
.st6 { fill: none; stroke: var(--color-yellow); stroke-miterlimit: 10; }
.st7 { fill: var(--color-white); }
.st8 { fill: var(--color-yellow); }
.Button_normal__1gPy8 {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: -webkit-inline-flex;
  display: inline-flex;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Button_normal__1gPy8:hover {
  text-decoration: none;
}

.Button_normal__1gPy8:focus, .Button_focus__btvXx {
  outline: none;
}

.Button_block__1-g4S {
  width: 100%;
}

.Button_disabled__9B1J6 {
  opacity: 0.5;
  pointer-events: none;
}

.Button_default__a7D3k {
  color: var(--button-text-default);
  background-color: var(--button-color-default);
}

.Button_default__a7D3k:hover {
  color: var(--button-hoverText-default);
  background-color: var(--button-hover-default);
}

.Button_link__3gne4, 
.Button_link__3gne4:hover {
  text-decoration: none;
}

.Button_secondary__5zU-L {
  background-color: transparent;
}

.Button_primary__VvbWv {
  color: var(--button-text-primary);
  background-color: var(--button-color-primary);
  border-color: var(--button-color-primary);
}

.Button_outline__5TOS9.Button_primary__VvbWv {
  color: var(--button-text-primary);
  background-color: transparent;
}

.Button_primary__VvbWv:hover {
  color: var(--button-hoverText-primary);
  background-color: var(--button-hover-primary);
  border-color: var(--button-hover-primary);
}

.Button_secondary__5zU-L {
  color: var(--button-text-secondary);
  background-color: var(--button-color-secondary);
  border-color: var(--button-color-secondary);
}

.Button_secondary__5zU-L.Button_outline__5TOS9 {
  color: var(--button-color-secondary);
  background-color: transparent;
}

.Button_secondary__5zU-L:hover,
.Button_secondary__5zU-L.Button_outline__5TOS9:hover {
  color: var(--button-hoverText-secondary);
  background-color: var(--button-hover-secondary);
  border-color: var(--button-hover-secondary);
}

.Button_danger__3Mf1F {
  text-decoration: underline;
  border-color: var(--button-border-danger);
  color: var(--button-text-danger);
  background-color: var(--button-color-danger);
}

.Button_danger__3Mf1F:hover {
  text-decoration: underline;
  background-color: var(--button-hover-danger);
  color: var(--button-hoverText-danger);
  border-color: var(--button-hoverBorder-danger);
}

.Button_normal__1gPy8 {
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  font-weight: var(--text-weight-normal);
}

.Button_small__wPvn2 {
  border-radius: 0.1805rem;
  line-height: 1.5;
  padding: 0.1205rem 0.35rem 0.2rem;
  font-size: var(--text-size-small);
  font-weight: var(--text-weight-normal);
}

.Button_medium__99Tea {
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.475rem 0.75rem;
  font-weight: var(--text-weight-normal);
}

.Button_large__2ZONK {
  border-radius: 0.25rem;
  line-height: 1.65;
  padding: 0.75rem 1rem;
  font-weight: var(--text-weight-normal);
} 

.Button_extra-large__3nqNk {
  border-radius: 0.25rem;
  line-height: 1.85;
  padding: 0.95rem 1.21rem;
  font-weight: var(--text-weight-normal);
}

.Button_loading__BLZi7 {
  pointer-events: none;
  cursor: default;
}

.Button_disabled__9B1J6 {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

