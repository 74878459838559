.default {
  position: relative;
}

.show {
  display: block;
}

.toggle {
  align-items: center;
  color: var(--color-text);
  display: inline-flex;
  white-space: nowrap;
  text-align: inherit;
  background-color: transparent;
  border-width: 0;
}

.toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.toggle:empty::after {
  margin-left: 0;
}

.menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: var(--text-size-normal);
  color: var(--color-text);
  text-align: left;
  list-style: none;
  background-color: var(--color-white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15rem;
}

.menu-show {
  display: block;
}

.menu-left {
  left: 0;
  right: auto;
}

.menu-right {
  left: auto;
  right: 0;
}

.item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--color-text);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
