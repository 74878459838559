.default {
  display: inline-flex;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: var(--text-size-medium);
  font-weight: var(--text-weight-bolder);
  line-height: inherit;
  white-space: nowrap;
}

a.default {
  color: var(--color-dark);
  text-decoration: none;
}