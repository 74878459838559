.wrapper {
  display: inline-flex;
}

.block, .block svg {
  display: block;
}

.small svg {
  width: 16px;
  height: auto;
}

.medium svg {
  width: 32px;
  height: auto;
}

.large svg {
  width: 64px;
  height: auto;
}

.extraLarge svg {
  width: 120px;
  height: auto;
}
