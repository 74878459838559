.default {
  position: relative;
  padding: 0.615rem 1.25rem;
  margin-bottom: 1rem;
  border: 3px solid transparent;
  border-width: 0 0 0 3px;
}

.error {
  color: var(--alert-danger-text);
  background-color: var(--alert-danger-color);
  border-color: var(--alert-danger-border);
}

.info {
  color: var(--alert-info-text);
  background-color: var(--alert-info-color);
  border-color: var(--alert-info-border);
}
