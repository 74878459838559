.title {
  font-size: var(--title-display-secondary-size);
  font-weight: var(--text-weight-bolder);
}

.text {
  color: var(--color-muted);
  font-size: var(--text-size-lead);
  font-weight: var(--text-weight-normal);
}

.row {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.logoWrapper {
  flex: 0 0 40%;
  flex-grow: 1;
  max-width: 40%;
}

.logoWrapper svg {
  max-width: 100%;
  height: auto;
}

.content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-grow: 1;
  flex: 0 0 60%;
  max-width: 60%;
}

@media (min-width: 1200px) {
  .container {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
  }
}