.size-1, .size-2, .size-3, .size-4, .size-5, .size-6, .size-7, .size-8, .size-9, .size-10, .size-11, .size-12, .default,
.size-auto, .size-sm-1, .size-sm-2, .size-sm-3, .size-sm-4, .size-sm-5, .size-sm-6, .size-sm-7, .size-sm-8, .size-sm-9, .size-sm-10, .size-sm-11, .size-sm-12, .size-sm,
.size-sm-auto, .size-md-1, .size-md-2, .size-md-3, .size-md-4, .size-md-5, .size-md-6, .size-md-7, .size-md-8, .size-md-9, .size-md-10, .size-md-11, .size-md-12, .size-md,
.size-md-auto, .size-lg-1, .size-lg-2, .size-lg-3, .size-lg-4, .size-lg-5, .size-lg-6, .size-lg-7, .size-lg-8, .size-lg-9, .size-lg-10, .size-lg-11, .size-lg-12, .size-lg,
.size-lg-auto, .size-xl-1, .size-xl-2, .size-xl-3, .size-xl-4, .size-xl-5, .size-xl-6, .size-xl-7, .size-xl-8, .size-xl-9, .size-xl-10, .size-xl-11, .size-xl-12, .size-xl,
.size-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.default {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.noGutter {
  padding-left: 0;
  padding-right: 0;
}

.size-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.size-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.size-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.size-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.size-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.size-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.size-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.size-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.size-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.size-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.size-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.size-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .size-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .size-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .size-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .size-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .size-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .size-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .size-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .size-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .size-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .size-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .size-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .size-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .size-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .size-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .size-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .size-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .size-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .size-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .size-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .size-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .size-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .size-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .size-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .size-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .size-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .size-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .size-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .size-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }
  
  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .size-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .size-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .size-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .size-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .size-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .size-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .size-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .size-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .size-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .size-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .size-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .size-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .size-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .size-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }
  
  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }
  
  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .size-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .size-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .size-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .size-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .size-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .size-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .size-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .size-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .size-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .size-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .size-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .size-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .size-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .size-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }
  
  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }
  
  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}