.normal {
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.normal:hover {
  text-decoration: none;
}

.normal:focus, .focus {
  outline: none;
}

.block {
  width: 100%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.default {
  color: var(--button-text-default);
  background-color: var(--button-color-default);
}

.default:hover {
  color: var(--button-hoverText-default);
  background-color: var(--button-hover-default);
}

.link, 
.link:hover {
  text-decoration: none;
}

.secondary {
  background-color: transparent;
}

.primary {
  color: var(--button-text-primary);
  background-color: var(--button-color-primary);
  border-color: var(--button-color-primary);
}

.outline.primary {
  color: var(--button-text-primary);
  background-color: transparent;
}

.primary:hover {
  color: var(--button-hoverText-primary);
  background-color: var(--button-hover-primary);
  border-color: var(--button-hover-primary);
}

.secondary {
  color: var(--button-text-secondary);
  background-color: var(--button-color-secondary);
  border-color: var(--button-color-secondary);
}

.secondary.outline {
  color: var(--button-color-secondary);
  background-color: transparent;
}

.secondary:hover,
.secondary.outline:hover {
  color: var(--button-hoverText-secondary);
  background-color: var(--button-hover-secondary);
  border-color: var(--button-hover-secondary);
}

.danger {
  text-decoration: underline;
  border-color: var(--button-border-danger);
  color: var(--button-text-danger);
  background-color: var(--button-color-danger);
}

.danger:hover {
  text-decoration: underline;
  background-color: var(--button-hover-danger);
  color: var(--button-hoverText-danger);
  border-color: var(--button-hoverBorder-danger);
}

.normal {
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  font-weight: var(--text-weight-normal);
}

.small {
  border-radius: 0.1805rem;
  line-height: 1.5;
  padding: 0.1205rem 0.35rem 0.2rem;
  font-size: var(--text-size-small);
  font-weight: var(--text-weight-normal);
}

.medium {
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.475rem 0.75rem;
  font-weight: var(--text-weight-normal);
}

.large {
  border-radius: 0.25rem;
  line-height: 1.65;
  padding: 0.75rem 1rem;
  font-weight: var(--text-weight-normal);
} 

.extra-large {
  border-radius: 0.25rem;
  line-height: 1.85;
  padding: 0.95rem 1.21rem;
  font-weight: var(--text-weight-normal);
}

.loading {
  pointer-events: none;
  cursor: default;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
