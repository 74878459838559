.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 20em;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}