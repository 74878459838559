:root {
  /* colors */
  --color-white: #ffffff;
  --color-text: #24292e;
  --color-rule: #e1e1e1;
  --color-muted: #888888;
  --color-dark: #000000;
  --color-yellow: #f5eb09;
  --color-blue: #0366d6;
  --color-light: #fafafa;
  --color-loading: #f4f4f4;

  /* controls */
  --control-text: #495057;
  --control-color: var(--color-white);
  --control-border: #ced4da;
  --control-padding: 0.375rem 0.6rem;
  --control-font-size: 14px;
  --control-placeholder: rgba(0,0,0,0.3);
  --control-disabled: #e9ecef;
  --control-color-focus: var(--color-white);
  --control-border-focus: var(--color-dark);
  --control-text-focus: #495057;
  --control-box-shadow-focus: rgba(0,0,0,0.1);
  --control-custom-color: #ffffff;
  --control-custom-border: #adb5bd;
  --control-custom-active-text: #ffffff;
  --control-custom-active-color: #000000;

  /* badges */
  --badge-primary-color: transparent;
  --badge-primary-text: #111111;
  --badge-primary-border: #dddddd;

  --badge-success-color: transparent;
  --badge-success-text: green;
  --badge-success-border: green;

  --badge-danger-color: transparent;
  --badge-danger-text: red;
  --badge-danger-border: red;
  
  /* buttons */
  --button-color-default: #f4f4f4;
  --button-text-default: #212529;
  --button-hover-default: #f1f1f1;
  --button-hoverText-default: #212529;

  --button-color-primary: #f5eb09;
  --button-text-primary: #000000;
  --button-hover-primary: #000000;
  --button-hoverText-primary: #ffffff;

  --button-color-secondary: #000000;
  --button-text-secondary: #ffffff;
  --button-hover-secondary: #111111;
  --button-hoverText-secondary: #ffffff;

  --button-text-danger: #f23459;
  --button-color-danger: transparent;
  --button-border-danger: transparent;

  --button-hover-danger: #f23459;
  --button-hoverText-danger: #ffffff;
  --button-hoverBorder-danger: #f23459;

  /* background colors */
  --background-gray-dark: #24292e;

  /* normal text */
  --text-size-small: 11px;
  --text-size-normal: 14px;
  --text-size-medium: 16px;
  --text-size-lead: 18px;
  --text-weight-normal: 400;
  --text-weight-medium: 600;
  --text-weight-bold: 700;
  --text-weight-bolder: 900;

  --title-primary: 41px;
  --title-secondary: 38px;
  --title-tertiary: 32px;

  --title-size-1: 30px;
  --title-size-2: 25px;
  --title-size-3: 16px;
  --title-size-4: 14px;
  --title-size-5: 12px;
  --title-size-6: 10px;

  /* titles */
  --title-display-primary-size: 54px;
  --title-display-secondary-size: 48px;

  /* tables */
  --row-line-color: #eaecef;

  /* alert */
  --alert-danger-color: #FFF9FB;
  --alert-danger-border: #FFCCD7;
  --alert-danger-text: #F23459;

  --alert-info-color: #FFF9EF;
  --alert-info-border: #FDD88E;
  --alert-info-text: #B24319;

  /* font families */
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-serif: serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
