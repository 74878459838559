@keyframes spinnerCircular {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circular {
  display: flex;
  position: relative;
}

.circular span {
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border-color: var(--color-dark) transparent transparent transparent;
  animation: spinnerCircular 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.inverse span {
  border-top-color: var(--color-white);
}

.circular span:nth-child(1) {
  animation-delay: -0.45s;
}

.circular span:nth-child(2) {
  animation-delay: -0.3s;
}

.circular span:nth-child(3) {
  animation-delay: -0.15s;
}

.size-normal {
  width: 32px;
  height: 32px;
}

.size-tiniest {
  width: 10px;
  height: 10px;
}

.size-tiny {
  width: 16px;
  height: 16px;
}

.size-tiniest span,
.size-tiny span {
  border-width: 1px;
}

.size-small {
  width: 22px;
  height: 22px;
}

.size-medium {
  width: 42px;
  height: 42px;
}

.size-large {
  width: 64px;
  height: 64px;
}
