.default {
  position: relative;
  width: 100%;
  margin-top: auto;
  padding-bottom: 1rem;
  z-index: 1001;
}

.noMargin {
  margin-top: 0;
}