.default {
  display: block;
  max-width: 100%;
  height: auto !important;
}

.loading {
  display: block;
  background-color: var(--color-loading);
  max-width: 100%;
  height: auto !important;
}
