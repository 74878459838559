.muted {
  color: var(--color-muted);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.small {
  font-size: var(--text-size-small);
}