.common {
  display: flex;
  flex-direction: column;
  position: relative;  
  width: 100%;
  border-top: 4px solid var(--color-yellow);
  min-height: calc(100vh - 4px);
}

.flex {
  display: flex;
  flex-direction: column;
}

.horizontallyCentered {
  justify-content: center;
}

.verticalCentered {
  align-items: center;
}

@media (min-width: 1200px) {
  .common {
    height: 100%;
    min-height: 450px;
  }

  .auto {
    min-height: auto;
  }
}
