.link {
  display: inline-flex;
  margin: 0 0.315rem;
  color: var(--color-muted);
}

.link::after {
  content: "|";
  display: inline-flex;
  margin: 0 0.5rem 0 1rem;
  color: rgba(0,0,0,0.12);
}

.link:hover {
  color: var(--color-dark );
}

.link svg {
  color: currentColor;
  max-width: 16px;
  height: auto;
}