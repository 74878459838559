.level-1 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-1);
}

.level-2 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-2);
}

.level-3 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-3);
}

.level-4 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-4);
}

.level-5 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-5);
}

.level-6 {
  font-weight: var(--text-weight-bold);
  font-size: var(--title-size-6);
}