.wrapper {
  width: 100%;
}

.default {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 1200px) {
  .wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}