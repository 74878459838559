.td, .th {
  text-align: left;
  vertical-align: middle;
}

.th {
  padding: 0.75rem 0;
  font-weight: var(--text-weight-bold);
}

.td {
  padding: 0.5rem 0;
  border-top: 1px solid var(--row-line-color);
  line-height: 1.5;
  font-size: var(--text-size-normal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}