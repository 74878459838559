* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  min-height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: var(--color-white);
  color: var(--color-text);
  font-family: var(--font-family-sans-serif);
  font-size: var(--text-size-normal);
  line-height: 1.5;
  min-height: 100vh;
  word-wrap: break-word;
}

.full-height {
  min-height: 100vh;
}

@media (min-width: 1200px) {
  .full-height {
    height: 100vh;
  }
}

/* svg logo */
.st0 { fill:var(--color-dark); }
.st1 { fill: none; stroke: var(--color-yellow); stroke-width: 0.5; stroke-miterlimit: 10;}
.st2 { fill: var(--color-yellow); }
.st3 { fill: var(--color-yellow); }
.st4 { fill: none; stroke: var(--color-yellow); stroke-miterlimit: 10; }
.st5 { fill: var(--color-white); }
.st6 { fill: none; stroke: var(--color-yellow); stroke-miterlimit: 10; }
.st7 { fill: var(--color-white); }
.st8 { fill: var(--color-yellow); }