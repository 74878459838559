.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 1rem;
  border-radius: 10em;
  background-color: var(--color-white);
  display: block;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.12);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: tralsnate(-50%, -50%);
}
