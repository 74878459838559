.container {
  display: flex;
  width: 100%;
  align-items: center;
}

.left {
  justify-content: start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: end;
}