.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1050;
  overflow-y: auto;
  position: relative;
  z-index: 10;
}

@media (min-width: 1200px) {
  .layout {
    height: 100%;
  }
}
